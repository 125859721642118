:root {
    --color-primary: #239380;
    --color-secondary: #90989E;
    --color-text-default: #191919;
    --color-text-muted: #A2A2A2;

    --color-default: #2D343E;
    --color-white: #FFF;
    --color-light-grey: #F8F9FA;
    --color-dark-grey: #BEC2C6;
    --color-dark: #343A40;

    --color-success: #00ab66;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #1d6e5b;
    --color-secondary-dark: #424D56;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #23b4a1;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #a2a2a2;

    --font-default: verdana, helvetica, arial, sans-serif;
    --font-default-bold: verdana, helvetica, arial, sans-serif;
    --font-default-medium: verdana, helvetica, arial, sans-serif;

    --font-size-default: 1rem;
}
